// extracted by mini-css-extract-plugin
export var banner = "akada-book-festival-module--banner--XMkbK";
export var banner__image = "akada-book-festival-module--banner__image--l2UJ7";
export var countdown = "akada-book-festival-module--countdown--gX3sq";
export var countdown__title = "akada-book-festival-module--countdown__title--zbkbp";
export var countdown__timersection = "akada-book-festival-module--countdown__timersection--+zgqo";
export var timer = "akada-book-festival-module--timer--1zEzi";
export var timer__value = "akada-book-festival-module--timer__value--Z37jV";
export var timer__subvalue = "akada-book-festival-module--timer__subvalue--9Pn8t";
export var timer__end = "akada-book-festival-module--timer__end--c7Equ";
export var dateSection = "akada-book-festival-module--dateSection--H3AY6";
export var dateSection__value = "akada-book-festival-module--dateSection__value--W2Ixc";
export var dateSection__text = "akada-book-festival-module--dateSection__text--fte5c";
export var dateSection__subtext = "akada-book-festival-module--dateSection__subtext--T7SYY";
export var dateSection__note = "akada-book-festival-module--dateSection__note--FtJ4P";
export var aboutEvent = "akada-book-festival-module--aboutEvent--gp6zt";
export var aboutEvent__text = "akada-book-festival-module--aboutEvent__text--H4CYF";
export var moreEvent = "akada-book-festival-module--moreEvent--KPSxF";
export var moreEvent__link = "akada-book-festival-module--moreEvent__link--9liTG";